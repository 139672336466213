/*
* time_controlling_frontend - /core/services/timecontrolling/timecontrolling.service.ts
* @autor: hburgos089@gmail.com
* @version: 1.0.0
* fecha: 08-2020
*/
import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {URL_SERVICIOS} from '../../config/config';
import {UsuarioService} from '../usuario/usuario.service';
import swal from 'sweetalert';
import {Timecontrolling} from '../../models/timecontrolling.model';
import {Observable} from 'rxjs';
import {HelpersService} from '../helpers/helpers.service';
import {retry} from 'rxjs/operators';

@Injectable(
    { providedIn: 'root' }
)
export class TimecontrollingService {
    totalTimecontrollings: number;
    timecontrollings: Timecontrolling [] = [];
    porDia: any;
    horaDia = 0;
    i = 0;
    j = 0;
    timesDiarios: Timecontrolling [] = [];
    porDiario = new EventEmitter<number>();

    constructor(
        public http: HttpClient,
        public usuarioService: UsuarioService,
        // public helpersService: HelpersService
    ) {
        let i = 0;
        const obsDia$ = new Observable( observer => {
           observer.next(this.porDia);
           const intervalo =  setInterval( () => {
              i++;
              observer.next(i);
              if (i === 4) {
                  clearInterval(intervalo);
                  observer.complete();
              }
              if (i === 2) {
                  observer.error(' Tiempo exedido');
              }
           }, 1000);
        });

        // obsDia$.pipe(
        //     retry()
        // ).subscribe(
        //     valor => console.log('subscribe: ', valor),
        //     error => console.warn('Error: ', error),
        //     () => console.log('Obser. terminado')
        // );
    }

    listarTimecontrollings(desde: number) {
        let url = URL_SERVICIOS + '/timecontrolling/desde/' + desde;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listar() {
        let url = URL_SERVICIOS + '/timecontrolling';
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorUsuarioMesYear(usuario: number, mes: number, year: number) {
        let url = URL_SERVICIOS + '/v_timecontrolling/usuario/' + usuario + '/mes/' + mes + '/year/' + year;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorMesYear(mes: number, year: number) {
        let url = URL_SERVICIOS + '/v_timecontrolling/mes/' + mes + '/year/' + year;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorUsuarioMesYearCentroCosto(usuario: number, mes: number, year: number, centroCosto: string) {
        let url = URL_SERVICIOS + '/v_timecontrolling/usuario/' + usuario + '/mes/' + mes + '/year/' + year + '/centroCosto/' + centroCosto;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorFecha(fecha: any) {
        let url = URL_SERVICIOS + '/v_timecontrolling/fecha/' + fecha + '/usuario/' + this.usuarioService.usuario.id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            // .pipe()
            .map((resp: any) => {
                /*---  ---*/
                this.totalTimecontrollings = resp.total;
                this.timecontrollings = resp.v_timecontrollings;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarCalendarioPorFecha(fecha: any, i: number, j: number) {
        let url = URL_SERVICIOS + '/v_timecontrolling/fecha/' + fecha + '/usuario/' + this.usuarioService.usuario.id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            // .pipe()
            .map((resp: any) => {
                /*---  ---*/
                this.i = i;
                this.j = j;
                this.totalTimecontrollings = resp.total;
                this.timecontrollings = resp.v_timecontrollings;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarResumenPorFecha(fecha: any) {
        let url = URL_SERVICIOS + '/v_timecontrolling/fecha/' + fecha + '/usuario/' + this.usuarioService.usuario.id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorUsuario() {
        let url = URL_SERVICIOS + '/v_timecontrolling/usuario/' + this.usuarioService.usuario.id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarVistaPorNombre(nombre: string) {
        let url = URL_SERVICIOS + '/v_timecontrolling/nombre/' + nombre;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalTimecontrollings = resp.total;
                return resp.v_timecontrollings;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    cargarTimecontrolling(id: number) {
        let url = URL_SERVICIOS + '/timecontrolling/' + id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => resp.timecontrollings)
            .catch(err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    guardarTimecontrolling(timecontrolling: Timecontrolling) {

        let url = URL_SERVICIOS + '/timecontrolling';
        if (timecontrolling.id) {
            /*- actualizando Timecontrollings **/
            url += '/' + timecontrolling.id;
            url += '?token=' + this.usuarioService.token;

            return this.http.put(url, timecontrolling)
                .map((resp: any) => {
                    swal('Registro actualizado', '', 'success');
                    return resp.timecontrollings;
                })
                .catch(err => {
                    swal('Error al actualizar!', 'No fue posible actualizar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        } else {
            /*- creando Timecontrollings **/
            url += '?token=' + this.usuarioService.token;
            return this.http.post(url, timecontrolling)
                .map((resp: any) => {
                    swal('Registro Creado', '', 'success');
                    return resp.timecontrollings;
                })
                .catch(err => {
                    swal('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        }
    }

    guardarTimecontrollingMatriz(timecontrolling: Timecontrolling) {

        let url = URL_SERVICIOS + '/timecontrolling';
        if (timecontrolling.id) {
            /*- actualizando Timecontrollings **/
            url += '/' + timecontrolling.id;
            url += '?token=' + this.usuarioService.token;

            return this.http.put(url, timecontrolling)
                .map((resp: any) => {
                    // swal('Timecontrolling actualizado', '', 'success');
                    return resp.timecontrollings;
                })
                .catch(err => {
                    // swal('Error al actualizar!', 'No fue posible actualizar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        } else {
            /*- creando Timecontrollings **/
            url += '?token=' + this.usuarioService.token;
            return this.http.post(url, timecontrolling)
                .map((resp: any) => {
                    // swal('Timecontrolling Creado', '', 'success');
                    return resp.timecontrollings;
                })
                .catch(err => {
                    // swal('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        }
    }

    borrarTimecontrolling(id: number) {
        let url = URL_SERVICIOS + '/timecontrolling/' + id;
        url += '?token=' + this.usuarioService.token;

        return this.http.delete(url)
            .map(resp => {
                swal('Registro Borrado', 'registro eliminado correctamente', 'success');
                return resp;
            })
            .catch(err => {
                swal('Error al borrar!', 'No fue posible borrar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    public porcentajeDiario(fecha: any)  {
         this.listarPorFecha( fecha).subscribe( datos => {
            let horas = 0;
            let porcentaje = 0;
            for (const times of datos) {
                horas += times.horas;
            }
            // console.log('horas= ' + resp.totalHoras);
            porcentaje = horas * 100 / this.usuarioService.usuario.horas;
            porcentaje = Number(porcentaje);
            this.porDia = porcentaje;
            console.log(this.porDia);
            return this.porDiario;
        });
         console.log(this.porDia);
    }

}
