import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AdminGuard} from './admin.guard';
import {VerificaTokenGuard} from './verifica-token.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class GuardsModule { }
