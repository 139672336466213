import {Component, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsuarioService} from '../../core/services/usuario/usuario.service';
import {Usuario} from '../../core/models/usuario.model';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
// declare var swal;
// @ts-ignore
import Swal from 'sweetalert2';
import {AuthGoogleService} from "../../core/services/auth-google.service";
// @ts-ignore
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';
import jwt_decode from "jwt-decode";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  email: string;
  recuerdame: boolean;
  auth2: any;
  btnStatus = false;
  viewPass = false;
  fecha = new Date();
  forma: FormGroup;
  responseGoogle: any;
  // @ts-ignore
  usuario: Usuario = new Usuario();
  constructor(
      public router: Router,
      public usuarioService: UsuarioService,
      public authGoogleService: AuthGoogleService,
      public _ngZone: NgZone,
  ) {
    this.forma = new FormGroup({
      nombre: new FormControl(''),
      email: new FormControl(localStorage.getItem('email'), [ Validators.required, Validators.pattern( '[a-zA-Z0-9_]+([.][a-zA-Z0-9_' +
          ']+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}')]),
      telefono: new FormControl(''),
      role: new FormControl('0'),
      estado: new FormControl('0'),
      zona: new FormControl('0'),
      area: new FormControl('0'),
      cargo: new FormControl('0'),
      horas: new FormControl(''),
      visualizacion: new FormControl('0'),
      descripcion: new FormControl(''),
      img: new FormControl(''),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      nombreEstado: new FormControl(''),
      nombreZona: new FormControl(''),
      nombreArea: new FormControl(''),
      nombreCargo: new FormControl(''),
      id: new FormControl(''),
      createdAt: new FormControl(''),
      updatedAt: new FormControl('')
    });
  }

  ngOnInit() {
    this.email = localStorage.getItem('email') || '';
    if ( this.email.length > 1 ) {
      this.recuerdame = true;
    }
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: "146352848250-g0tn0q0j7da5nkr04mtru3dm6em6tb02.apps.googleusercontent.com",
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.renderButton(
          // @ts-ignore
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large", width: "100%" }
      );
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => {});
    };
      //   window.onload = function () {
      //     // @ts-ignore
      //     google.accounts.id.initialize({
      //       client_id: "146352848250-g0tn0q0j7da5nkr04mtru3dm6em6tb02.apps.googleusercontent.com",
      //       callback: handleCredentialResponse
      //     });
      //     // @ts-ignore
      //     google.accounts.id.renderButton(
      //         document.getElementById("buttonDiv"),
      //         { theme: "outline", size: "large" }  // customization attributes
      //     );
      //     // @ts-ignore
      //     google.accounts.id.prompt(); // also display the One Tap dialog
      //   }
      // function handleCredentialResponse(response) {
      //   // console.log("Encoded JWT ID token: " + response.credential);
      //   const responsePayload = jwt_decode(response.credential)
      //   // @ts-ignore
      //   console.log("Email: " + responsePayload.email);
      //   // localStorage(responsePayload,'res')
      //   return responsePayload
      // }

  }

  async handleCredentialResponse(response: CredentialResponse) {
    this.responseGoogle= jwt_decode(response.credential);
    // console.log(this.responseGoogle);
    //this.loginWithGoogle(this.responseGoogle.email);
    this.usuario = this.forma.value;
    this.usuario.email = this.responseGoogle.email;
    this.usuarioService.loginWithGoogle(this.usuario, true)
        .subscribe( datos => {
          if (datos) {
            this._ngZone.run(() => {
              this.router.navigate(['/dashboard']);
              Swal.fire({
                title: 'CChC',
                text: 'Bienvenida(o) ' + this.usuarioService.usuario.nombre,
                imageUrl: 'assets/images/logo-CChc.png',
                imageHeight: 50,
                animation: false,
                // position: 'top-end',
                // type: 'success',
                showConfirmButton: false,
                timer: 2500
              });
            });
          } else {
            Swal.fire({
              type: 'error',
              title: '¡Acceso denegado!!',
              text: 'Esta cuenta no posee permiso para acceder al sistema o escribió mal la contraseña, verifique sus datos eh intente nuevamente. ',
              footer: 'En caso de algún problema contáctese con el administrador del sistema',
              timer: 3500
            });
          }
          // this.router.navigate(['/dashboard']);
        });

  }

    ingresar( forma: NgForm) {
    /*--- si el formulario es invalido salir   ---*/
    if ( forma.invalid ) {
      return;
    }
    // console.log('usuario: ' + usuario.email);
    // console.log('Pass: ' + usuario.password);
    // this.usuarioService.login( this.usuario, forma.value.recuerdame )
    //     .subscribe( correcto => {
    //       this.router.navigate(['/dashboard']);
    //     });
  }



  login() {
    this.btnStatus = true;
    this.usuario = this.forma.value;
    const passDefault = false;
    // passDefault = bcrypt.compareSync(this.usuario.password, '$2a$10$zIjSpEpi5NA.z32W4aKz5..J58xeOS6pVJqw9gSqD0sb7ZoU7rwUK');
    this.usuarioService.login(this.forma.value, true)
        .subscribe( datos => {
          console.warn(datos);
          this.router.navigate(['/dashboard']);
          this.btnStatus = false;
          // this.usuarioService.passwordDefault(this.usuario)
          //     .subscribe( datos => {
          //       console.log(datos);
          //       if (datos) {
          //         Swal.fire({
          //           title: 'Cambiar Password!',
          //           text: 'La password ingresada es la que viene por defecto en la activación de la cuenta, ' +
          //               'se recomienda cambiar la contraseña en la sección del perfil de usuario.',
          //           imageUrl: 'assets/images/logo-cdt.png',
          //           imageHeight: 60,
          //           animation: false,
          //           // position: 'top-end',
          //           // type: 'success',
          //           showConfirmButton: false,
          //           // timer: 2500
          //           footer: '<a href="#/perfil-usuario" [routerLink]="/perfil-usuario" ><i class="ti-user"></i> Ir al Perfil de usuario</a>'
          //         });
          //       } else {
          //         Swal.fire({
          //           title: 'CChC',
          //           text: 'Bienvenida(o) ' + this.usuarioService.usuario.nombre,
          //           imageUrl: 'assets/images/logo-CChc.png',
          //           imageHeight: 50,
          //           animation: false,
          //           // position: 'top-end',
          //           // type: 'success',
          //           showConfirmButton: false,
          //           timer: 2500
          //         });
          //       }
          //     });
        });
    setTimeout(() => {
          this.btnStatus = false;
        }, 2000);
  }

  loginWithGoogle(email: string) {
    this.usuario = this.forma.value;
    this.usuario.email = email;
    this.usuarioService.loginWithGoogle(this.usuario, true)
        .subscribe( datos => {
          this.router.navigate(['/dashboard']);
          Swal.fire({
            title: 'CChC',
            text: 'Bienvenida(o) ' + this.usuarioService.usuario.nombre,
            imageUrl: 'assets/images/logo-CChc.png',
            imageHeight: 50,
            animation: false,
            // position: 'top-end',
            // type: 'success',
            showConfirmButton: false,
            timer: 2500
          });
        });
  }

  recordar(valor: boolean, ) {
    console.log(valor);
    if ( valor ) {
      localStorage.setItem('email', this.forma.value.email );
    } else {
      localStorage.removeItem('email');
    }
  }

  verPassword( valor: any) {
    console.log(valor);
  }

}
