import { Component, OnInit } from '@angular/core';
import {Router, ActivationEnd} from '@angular/router';
import {Meta, Title, MetaDefinition} from '@angular/platform-browser';
import 'rxjs-compat/add/operator/filter';
import 'rxjs-compat/add/operator/map';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: []
})
export class BreadcrumbsComponent implements OnInit {
  label: string;
  icon: string;
  pages: string;
  constructor(
      private router: Router,
      public title: Title,
      public meta: Meta
  ) {
    this.getDataRoute()
        .subscribe( data => {

          this.label = data.titulo;
          this.icon = data.icon;
          this.pages = data.pages;
          this.title.setTitle( this.label );

          const metaTag: MetaDefinition = {
            name: 'description',
            content: this.label
          };

          this.meta.updateTag(metaTag);

        });
  }

  ngOnInit() {
  }

  getDataRoute() {

    return this.router.events
        .filter( evento => evento instanceof ActivationEnd  )
        .filter( (evento: ActivationEnd) => evento.snapshot.firstChild === null )
        .map( (evento: ActivationEnd) => evento.snapshot.data );

  }

}
