import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-portlet',
  templateUrl: './portlet.component.html',
  styleUrls: ['./portlet.component.scss']
})
export class PortletComponent implements OnInit {

  @Input() isCollapsed = false;
  @Input() title: string;
  @Input() color: string;
  @Input() text: string;
  @Input() headerClass: string;
  @Input() upload = false;
  @Input() icon = false;
  @Input() dropdownMenu = true;
  @Input() classIcon = 'ti-home';
  @Input() btnAdd = false;
  @Input() btnExcel = false;
  @Input() btnPdf = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onContentRefresh: EventEmitter<any> = new EventEmitter();
  @Output() addRegistro: EventEmitter<any> = new EventEmitter();
  @Output() exportarExcel: EventEmitter<any> = new EventEmitter();
  @Output() exportarPdf: EventEmitter<any> = new EventEmitter();

  isLoading: boolean;
  isVisible: boolean;

  constructor() { }

  ngOnInit() {
    // this.isCollapsed = false;
    this.isLoading = false;
    this.isVisible = true;
  }

  /**
   * Refreshes the content
   */
  refreshContent() {
    this.isLoading = true;

    // event emit to let parent know about data refresh
    this.onContentRefresh.emit();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  /**
   * Removes self from dom
   */
  remove() {
    this.isVisible = false;
  }

  agregarRegistro() {
    this.addRegistro.emit();
  }

  exportarArchivo() {
    this.exportarExcel.emit();
  }

  descargarPDF() {
    this.exportarPdf.emit();
  }

}


