<div class="btn-group">
  <button type="button" class="btn btn-sm btn-light waves-effect"><i class="mdi mdi-archive font-18"></i></button>
  <button type="button" class="btn btn-sm btn-light waves-effect"><i class="mdi mdi-alert-octagon font-18"></i></button>
  <button type="button" class="btn btn-sm btn-light waves-effect"><i
      class="mdi mdi-delete-variant font-18"></i></button>
</div>
<div class="btn-group ml-1" ngbDropdown>
  <button type="button" class="btn btn-sm btn-light dropdown-toggle waves-effect" data-toggle="dropdown"
    ngbDropdownToggle aria-expanded="false">
    <i class="mdi mdi-folder font-18"></i>
    <i class="mdi mdi-chevron-down"></i>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <span class="dropdown-header">Move to</span>
    <a class="dropdown-item" href="javascript: void(0);">Social</a>
    <a class="dropdown-item" href="javascript: void(0);">Promotions</a>
    <a class="dropdown-item" href="javascript: void(0);">Updates</a>
    <a class="dropdown-item" href="javascript: void(0);">Forums</a>
  </div>
</div>
<div class="btn-group ml-1" ngbDropdown>
  <button type="button" class="btn btn-sm btn-light dropdown-toggle waves-effect" data-toggle="dropdown"
    ngbDropdownToggle aria-expanded="false">
    <i class="mdi mdi-label font-18"></i>
    <i class="mdi mdi-chevron-down"></i>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <span class="dropdown-header">Label as:</span>
    <a class="dropdown-item" href="javascript: void(0);">Updates</a>
    <a class="dropdown-item" href="javascript: void(0);">Social</a>
    <a class="dropdown-item" href="javascript: void(0);">Promotions</a>
    <a class="dropdown-item" href="javascript: void(0);">Forums</a>
  </div>
</div>

<div class="btn-group ml-1" ngbDropdown>
  <button type="button" class="btn btn-sm btn-light dropdown-toggle waves-effect" data-toggle="dropdown"
    ngbDropdownToggle aria-expanded="false">
    <i class="mdi mdi-dots-horizontal font-18"></i> More
    <i class="mdi mdi-chevron-down"></i>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <span class="dropdown-header">More Option :</span>
    <a class="dropdown-item" href="javascript: void(0);">Mark as Unread</a>
    <a class="dropdown-item" href="javascript: void(0);">Add to Tasks</a>
    <a class="dropdown-item" href="javascript: void(0);">Add Star</a>
    <a class="dropdown-item" href="javascript: void(0);">Mute</a>
  </div>
</div>