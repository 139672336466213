<div class="card" *ngIf="isVisible">
  <!-- card header -->
  <div class="card-header bg-{{ color }} text-{{ text }} {{headerClass}}">
    <i class="{{classIcon}}" *ngIf="icon"></i> {{ title }}
    <button class="btn btn-rounded" style="color: #1abc9c" *ngIf="btnAdd" (click)="agregarRegistro()">
      <i class="font-18 fas fa-plus-square" ngbTooltip="Agregar nuevo registro"></i>
    </button>
<!--    <button class="btn btn-rounded" style="color: #1abc9c" *ngIf="btnExcel" (click)="exportarArchivo()">-->
<!--      <i class="font-22 mdi mdi-file-excel" ngbTooltip="Descargar archivo en excel"></i>-->
<!--    </button>-->
<!--    <a class="btn" style="color: #1abc9c" *ngIf="btnExcel" (click)="exportarArchivo()">-->
<!--      <i class="font-22 mdi mdi-file-excel" ngbTooltip="Descargar archivo en excel"></i>-->
<!--    </a>-->
<!--    <img src="./assets/images/iconos/excel.png" alt="" class="img-20" *ngIf="btnExcel" (click)="exportarArchivo()" ngbTooltip="Descargar archivo en excel">-->
<!--    <img src="./assets/images/iconos/pdf-download.png" alt="" class="img-25" *ngIf="btnPdf" (click)="descargarPDF()" ngbTooltip="Descargar archivo en pdf">-->
<!--    <button class="btn btn-rounded" style="color: #f30202" *ngIf="btnPdf" (click)="descargarPDF()">-->
<!--      <i class="font-30 fa fa-file-pdf" ngbTooltip="Descargar archivo en pdf"></i>-->
<!--    </button>-->
<!--    <a class="btn"  style="color: #f30202" *ngIf="btnPdf" (click)="descargarPDF()" ngbTooltip="Descargar archivo en pdf">-->
<!--      <i class="font-30 mdi mdi-file-pdf" ></i>-->
<!--    </a>-->
    <div class="card-widgets">
<!--      <a href="javascript: void(0);" *ngIf="upload === true" (click)="refreshContent()"><i class="mdi mdi-refresh"></i></a>-->
<!--      <a href="javascript: void(0);" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"-->
<!--         aria-expanded="false" aria-controls="cardCollpase5" class="mdi mdi-minus" [ngClass]="-->
<!--           {-->
<!--             'mdi-minus': isCollapsed == false,-->
<!--             'mdi-plus': isCollapsed == true-->
<!--           }">-->
<!--      </a>-->
      <ng-template [ngTemplateOutlet]="CardActions"></ng-template>
<!--            <a href="javascript: void(0);" (click)="remove()"><i class="mdi mdi-close"></i></a>-->
    </div>
  </div>
  <!-- End card header -->

  <!-- card body -->

  <div id="cardCollpase5" [ngbCollapse]="isCollapsed">
    <ng-content id="contenido"></ng-content>
  </div>

  <!-- End card body -->

  <div class="card-disabled" *ngIf="isLoading">
    <div class="card-portlets-loader">
      <div class="spinner-border text-primary m-2" role="status"></div>
    </div>
  </div>
</div>


<ng-template #CardActions>
  <div class="dropdown float-right" ngbDropdown>
    <a href="javascript: void(0)" class="dropdown-toggle arrow-none card-drop"
       data-toggle="dropdown" *ngIf="dropdownMenu"
       aria-expanded="false" ngbDropdownToggle>
      <i class="mdi mdi-dots-vertical-circle-outline"></i>
    </a>
    <a href="javascript: void(0)" class="dropdown-toggle arrow-none card-drop"
       (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
       aria-expanded="false" >
      <i class="mdi mdi-minus" [ngClass]="
           {
             'mdi-minus': isCollapsed == false,
             'mdi-plus': isCollapsed == true
           }"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
<!--      <a class="dropdown-item" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"-->
<!--         aria-expanded="false" aria-controls="cardCollpase5" ><i class="mdi mdi-minus" [ngClass]="-->
<!--           {-->
<!--             'mdi-minus': isCollapsed == false,-->
<!--             'mdi-plus': isCollapsed == true-->
<!--           }"></i>-->
<!--          <span *ngIf="isCollapsed == false">Minimizar tarjeta</span>-->
<!--          <span *ngIf="isCollapsed == true">Maximizar tarjeta</span>-->
<!--      </a>-->
      <!-- item-->
      <a href="javascript:void(0);" class="dropdown-item" *ngIf="upload === true" (click)="refreshContent()"><i class="mdi mdi-refresh"></i> Actualizar datos</a>
      <!-- item-->
      <a  class="dropdown-item" *ngIf="btnAdd" (click)="agregarRegistro()"><i class="fas fa-plus-square"></i> Agregar registro</a>
      <!-- item-->
      <a  class="dropdown-item" *ngIf="btnExcel" (click)="exportarArchivo()"><i class="mdi mdi-file-excel"></i> Descargar en excel</a>
      <!-- item-->
      <a class="dropdown-item" *ngIf="btnPdf" (click)="descargarPDF()"><i class="mdi mdi-file-pdf"></i> Descargar en pdf</a>
    </div>
  </div>
</ng-template>
