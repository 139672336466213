import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GuardsModule} from './guards/guards.module';
import {PipesModule} from './pipes/pipes.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GuardsModule,
      PipesModule
  ]
})
export class CoreModule { }
