import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UsuarioService} from '../services/usuario/usuario.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements  CanActivate {
  constructor(
      public usuarioService: UsuarioService,
      public router: Router,
  ) {}

  canActivate() {
    if (this.usuarioService.estaLogueado() ) {
      return true;
    } else {
      console.log( 'Login incorrecto' + this.usuarioService.token);

      this.router.navigate(['/login']);
      return false;
    }
  }
}
