<div class="topbar-menu">
    <div class="container-fluid">
        <div id="navigation" [ngbCollapse]="!showMobileMenu">
            <!-- Navigation Menu-->
            <ul class="navigation-menu">

                <li class="has-submenu" routerLinkActive="active">
                    <a href="javascript: void(0);" (click)="onMenuClick($event)">
                        <i class="ti-home"></i>Dashboards <div class="arrow-down"></div></a>
                    <ul class="submenu">
                        <li *ngIf="usuarioService.usuario.role !== 'DIRECTOR_ROLE'">
                            <a [routerLink]="['/dashboard']" class="side-nav-link-ref" routerLinkActive="active"><i class="ti-home"></i> Dashboard usuario</a>
                        </li>
                        <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'GEREN_ROLE' || usuarioService.usuario.role === 'CORP_ROLE' || usuarioService.usuario.role === 'DIRECTOR_ROLE'">
                            <a [routerLink]="['/gerencia']" class="side-nav-link-ref" routerLinkActive="active"><i class="ti-home"></i> Dashboard gerencia</a>
                        </li>
<!--                        <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'CORP_ROLE' || usuarioService.usuario.role === 'DIRECTOR_ROLE'">-->
<!--                            <a [routerLink]="['/corporativo']" class="side-nav-link-ref"-->
<!--                               routerLinkActive="active"><i class="mdi mdi-sitemap"></i> Dashboard corporativo</a>-->
<!--                        </li>-->
                    </ul>
                </li>

                <li class="has-submenu"  routerLinkActive="active" *ngIf="usuarioService.usuario.role !== 'DIRECTOR_ROLE'">
                    <a href="javascript: void(0);" (click)="onMenuClick($event)" [routerLinkActive]="'active'">
                        <i class="remixicon-dashboard-line"></i>Apps
<!--                        <span class="badge badge-warning rounded-circle noti-icon-badge"-->
<!--                                                                           *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' ||-->
<!--                                                                           usuarioService.usuario.role === 'GEREN_ROLE' || usuarioService.usuario.role === 'CORP_ROLE'">1</span>-->
                        <div class="arrow-down"></div>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a routerLink="/ingreso-horas" class="side-nav-link-ref" routerLinkActive="active"><i class="ti-timer"></i> Ingreso de Horas
                                </a>
                        </li>
<!--                        <li *ngIf="usuarioService.usuario.role !== 'USER_ROLE'">-->
<!--                            <a routerLink="/proyectos" class="side-nav-link-ref"-->
<!--                               routerLinkActive="active"><i class="remixicon-briefcase-5-line"></i> Proyectos-->
<!--&lt;!&ndash;                                <span class="badge badge-danger  noti-icon-badge">Nuevo</span>&ndash;&gt;-->
<!--                            </a>-->
<!--                        </li>-->

                        <li *ngIf="usuarioService.usuario.role !== 'USER_ROLE'">
                            <a routerLink="/usuarios" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="ti-user"></i> Usuarios</a>
                        </li>
                        <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
                            <a routerLink="/desbloqueo-formulario" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="mdi mdi-block-helper"></i> Desbloquear Formulario
                            </a>
                        </li>
                        <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
                            <a routerLink="/usuario-proyectos" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="mdi mdi-briefcase-check"></i> Horas por Proyecto
                               <span class="badge badge-danger  noti-icon-badge">Nuevo</span>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="has-submenu"  routerLinkActive="active" *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
                    <a href="javascript: void(0);" (click)="onMenuClick($event)"> <i
                            class="ti-folder"></i>Mantenedores <div class="arrow-down"></div></a>

                    <ul class="submenu">
                        <li>
                            <a routerLink="/usuarios" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="ti-user"></i> Usuarios</a>
                        </li>
                        <li>
                            <a routerLink="/areas" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="ti-direction-alt"></i> Áreas</a>
                        </li>
<!--                        <li>-->
<!--                            <a routerLink="/cargos" class="side-nav-link-ref"-->
<!--                               routerLinkActive="active"><i class="mdi mdi-card-bulleted"></i> Cargos</a>-->
<!--                        </li>-->
                        <div class="dropdown-divider"></div>
<!--                        <li>-->
<!--                            <a routerLink="/categoria-centro-costos" class="side-nav-link-ref"-->
<!--                               routerLinkActive="active"><i class="mdi mdi-cash-usd"></i> Categoría centro de costos</a>-->
<!--                        </li>-->
                        <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE'">
                            <a routerLink="/centro-costos-sap" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="mdi mdi-cash-usd"></i> Centros de Costos
<!--                                <span class="badge badge-danger  noti-icon-badge">Nuevo</span>-->
                            </a>
                        </li>
                        <li *ngIf="usuarioService.usuario.role === 'ADMIN_ROLE' || usuarioService.usuario.role === 'GERENT_ROLE'" >
                            <a routerLink="/proyectos-sap" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="remixicon-briefcase-5-line"></i> Proyectos
                                <!--                                <span class="badge badge-danger  noti-icon-badge">Nuevo</span>-->
                            </a>
                        </li>
                        <li>
                            <a routerLink="/procesos" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="mdi mdi-clock-fast"></i> Procesos</a>
                        </li>
                        <li>
                            <a routerLink="/actividades" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="mdi mdi-clock-fast"></i> Actividades</a>
                        </li>
                        <div class="dropdown-divider"></div>
                        <li>
                            <a routerLink="/feriados" class="side-nav-link-ref"
                               routerLinkActive="active"><i class="ti-calendar"></i> Feriados</a>
                        </li>
                    </ul>
                </li>


                <!--********************* INICIO Menu de usuario ********************************-->
<!--                <li class="has-submenu" *ngFor="let menu2 of navbarService.menu">-->
<!--                    <a href="javascript: void(0);" (click)="onMenuClick($event)"> <i-->
<!--                            [class]="menu2.icono"></i>{{menu2.titulo}} <div class="arrow-down"></div></a>-->
<!--                    <ul class="submenu">-->
<!--                        <li *ngFor="let submenu of menu2.submenu">-->
<!--                            <a [routerLink]="[submenu.url]" class="side-nav-link-ref" routerLinkActive="active"><i class="{{submenu.icon}}"></i> {{submenu.titulo}}</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </li>-->
                <!--****************** FIN Menu de usuario **************************************-->

                <li class="has-submenu"  routerLinkActive="active" >
                    <a href="javascript: void(0);" (click)="onMenuClick($event)" [routerLinkActive]="'active'">
                        <i class="ti-bar-chart-alt"></i>Reportes <div class="arrow-down"></div></a>
                    <ul class="submenu">
                        <li >
                            <a routerLink="/reportes-excel" class="side-nav-link-ref" routerLinkActive="active"><i class="ti-export"></i> Reportes registros diarios
                            </a>
                        </li>
                        <!--                        <li>-->
                        <!--                            <a routerLink="/proyectos" class="side-nav-link-ref"-->
                        <!--                               routerLinkActive="active"><i class="remixicon-briefcase-5-line"></i> Proyectos</a>-->
                        <!--                        </li>-->
                    </ul>
                </li>
<!--                <li class="has-submenu" *ngIf="usuario.role === 'ADMIN_ROLE' || usuario.role === 'CORP_ROLE' || usuario.role === 'GEREN_ROLE'"  routerLinkActive="active">-->
<!--                    <a [routerLink]="['/reportes-excel']">-->
<!--                        <i class="ti-export"></i>Reportes</a>-->
<!--                </li>-->

            </ul>
            <!-- End navigation menu -->

            <div class="clearfix"></div>
        </div>
        <!-- end #navigation -->
    </div>
    <!-- end container -->
</div>
<!-- end navbar-custom -->
