<!-- Topbar Start -->
<div class="navbar-custom">
  <div class="container-fluid">
    <ul class="list-unstyled topnav-menu float-right mb-0">

      <li class="dropdown notification-list">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle nav-link" [ngClass]="{'open': openMobileMenu}" (click)="toggleMobileMenu($event)">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </li>

      <!--********************* INICIO Buscador general ********************************-->
<!--      <li class="d-none d-sm-block">-->
<!--        <form class="app-search">-->
<!--          <div class="app-search-box">-->
<!--            <div class="input-group">-->
<!--              <input type="text" class="form-control" placeholder="Search...">-->
<!--              <div class="input-group-append">-->
<!--                <button class="btn" type="submit">-->
<!--                  <i class="fe-search"></i>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </form>-->
<!--      </li> -->
      <!--****************** FIN Buscador general **************************************-->

      <li class="dropdown notification-list" ngbDropdown>
        <a class="nav-link dropdown-toggle  waves-effect waves-light" href="javascript: void(0);" role="button"
          aria-haspopup="false" aria-expanded="false" ngbDropdownToggle id="notificationDropdown">
          <i class="fe-bell noti-icon"></i>
<!--          <span class="badge badge-danger rounded-circle noti-icon-badge">1</span>-->
        </a>
        <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg"
          aria-labelledby="notificationDropdown" ngbDropdownMenu>

          <!-- item-->
          <div class="dropdown-item noti-title" ngbDropdownItem>
            <h5 class="m-0">
              <span class="float-right">
<!--                <a href="javascript: void(0);" class="text-dark">-->
<!--                  <small>Limpiar lista</small>-->
<!--                </a>-->
              </span>Notificaciones
            </h5>
          </div>

          <div id="notification-items" class="slimscroll noti-scroll" appSlimScroll>
            <!-- item-->
<!--            <a-->
<!--              class="dropdown-item notify-item" ngbDropdownItem>-->
<!--              <div class="notify-icon bg-soft-info text-info">-->
<!--                <i class="ti-info"></i>-->
<!--              </div>-->
<!--              <p class="notify-details">Bloqueo registros meses anteriores-->
<!--                <small class="text-muted">Se bloquea la modificación de registros <br>-->
<!--                    de meses anteriores.-->
<!--                    <br> En el caso de fin de mes solo <br>tendrá la primera semana del mes para <br> modificar o agregar registros-->
<!--                    <br> del mes anterior-->
<!--                </small>-->
<!--              </p>-->
<!--            </a>-->


<!--            <a-->
<!--                    class="dropdown-item notify-item" ngbDropdownItem>-->
<!--              <div class="notify-icon bg-soft-info text-info">-->
<!--                <i class="ti-info"></i>-->
<!--              </div>-->
<!--              <p class="notify-details">Modificaciones en formulario repet. Actividades-->
<!--                <small class="text-muted">Se ordenan campos del form. en relación <br>con el orden del formulario de ingresos-->
<!--                  <br>masivo</small>-->
<!--              </p>-->
<!--            </a>-->
          </div>

          <!-- All-->
<!--          <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">-->
<!--            Ver todo-->
<!--          </a>-->
        </div>
      </li>

        <!--********************* INICIO Time Controlling ********************************-->
      <li class="dropdown notification-list" ngbDropdown>
            <a class="nav-link dropdown-toggle  waves-effect waves-light" href="javascript: void(0);" role="button"
               aria-haspopup="false" aria-expanded="false" ngbDropdownToggle id="notificationDropdown2">
                <i class="ti-timer noti-icon"></i>
                <span class="badge badge-success rounded-circle noti-icon-badge">{{timecontrollingService.totalTimecontrollings}}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg"
                 aria-labelledby="notificationDropdown2" ngbDropdownMenu>

                <!-- item-->
                <div class="dropdown-item noti-title" ngbDropdownItem>
                    <h5 class="m-0">
              <span class="float-right">
<!--                <a href="javascript: void(0);" class="text-dark">-->
                  <!--                  <small>Limpiar lista</small>-->
                  <!--                </a>-->
              </span>Ingreso de horas diarias
                    </h5>
                </div>

                <div id="notification-items2" class="slimscroll noti-scroll" appSlimScroll>
                    <!-- item-->
                    <a *ngFor="let notification of timecontrollingService.timecontrollings;"
                       class="dropdown-item notify-item" ngbDropdownItem>
                        <div class="notify-icon  text-dark">
                            <i class="ti-timer"></i>
                        </div>
                        <p class="notify-details">{{ notification.nombreActividad }}
                            <small class="text-muted">{{ notification.nombreProyectoSap }}, {{notification.horas}} horas</small>
                        </p>
                    </a>
                </div>

                <!-- All-->
                <a [routerLink]="['/ingreso-horas']" class="dropdown-item text-center text-primary notify-item notify-all">
                    Ver todo
                </a>
            </div>
        </li>
        <!--****************** FIN Time Controlling **************************************-->

      <li class="dropdown notification-list" ngbDropdown>
        <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" ngbDropdownToggle
          id="profileDropdown" href="javascript: void(0);" role="button" aria-haspopup="false" aria-expanded="false">
          <img *ngIf="usuario.img === ''" src="assets/images/users/user.png" alt="user-image" class="rounded-circle">
          <img *ngIf="usuario.img !== ''" [src]="usuario.img | imagen" alt="user-image" class="rounded-circle">
          <span class="pro-user-name ml-1">
            {{usuario.nombre}} <i class="mdi mdi-chevron-down"></i>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right profile-dropdown" aria-labelledby="profileDropdown"
          ngbDropdownMenu>
          <!-- item-->
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bienvenid@ !</h6>
          </div>

          <!-- item-->
          <a (click)="cargarPerfil(usuario.id)"  class="dropdown-item notify-item">
            <i class="remixicon-account-circle-line"></i>
            <span>Mi perfil</span>
          </a>

          <!-- item-->
<!--          <a (click)="cambiarUsuario(usuario)" class="dropdown-item notify-item pointer" *ngIf="usuarioService.user2.role === 'ADMIN_ROLE'">-->
<!--            <i class="fa fa-user-check"></i>-->
<!--            <span>Vista Admin</span>-->
<!--          </a>-->

          <!-- item-->
<!--          <a href="javascript:void(0);" class="dropdown-item notify-item">-->
<!--            <i class="remixicon-wallet-line"></i>-->
<!--            <span>My Wallet <span class="badge badge-success float-right">3</span> </span>-->
<!--          </a>-->

          <!-- item-->
<!--          <a href="javascript:void(0);" class="dropdown-item notify-item">-->
<!--            <i class="remixicon-lock-line"></i>-->
<!--            <span>Bloquear pantalla</span>-->
<!--          </a>-->

          <div class="dropdown-divider"></div>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logout()">
            <i class="remixicon-logout-box-line"></i>
            <span>Cerrar sesión </span>
          </a>

        </div>
      </li>


<!--      <li class="dropdown notification-list">-->
<!--        <a href="javascript:void(0);" class="nav-link right-bar-toggle waves-effect waves-light"-->
<!--          (click)="toggleRightSidebar()">-->
<!--          <i class="fe-settings noti-icon"></i>-->
<!--        </a>-->
<!--      </li>-->


    </ul>

    <!-- LOGO -->
    <div class="logo-box">
      <a [routerLink]="['dashboard']" class="logo text-center">
        <span class="logo-lg">
          <img src="assets/images/logo-blanco-cchc.png" alt="" height="60">
          <!-- <span class="logo-lg-text-light">Xeria</span> -->
        </span>
        <span class="logo-sm">
          <!-- <span class="logo-sm-text-dark">X</span> -->
          <img src="assets/images/logo-blanco-cchc.png" alt="" height="30">
        </span>
      </a>
    </div>

<!--    <ul class="list-unstyled topnav-menu topnav-menu-left m-0">-->
<!--      <li class="dropdown d-none d-lg-block" ngbDropdown>-->
<!--        <a class="nav-link dropdown-toggle waves-effect waves-light" href="javascript: void(0);" ngbDropdownToggle-->
<!--          id="extraMenu" role="button" aria-haspopup="false" aria-expanded="false">-->
<!--          Create New-->
<!--          <i class="mdi mdi-chevron-down"></i>-->
<!--        </a>-->
<!--        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="extraMenu">-->
<!--          &lt;!&ndash; item&ndash;&gt;-->
<!--          <a href="javascript:void(0);" class="dropdown-item">-->
<!--            <i class="fe-briefcase mr-1"></i>-->
<!--            <span>New Projects</span>-->
<!--          </a>-->

<!--          &lt;!&ndash; item&ndash;&gt;-->
<!--          <a href="javascript:void(0);" class="dropdown-item">-->
<!--            <i class="fe-user mr-1"></i>-->
<!--            <span>Create Users</span>-->
<!--          </a>-->

<!--          &lt;!&ndash; item&ndash;&gt;-->
<!--          <a href="javascript:void(0);" class="dropdown-item">-->
<!--            <i class="fe-bar-chart-line- mr-1"></i>-->
<!--            <span>Revenue Report</span>-->
<!--          </a>-->

<!--          &lt;!&ndash; item&ndash;&gt;-->
<!--          <a href="javascript:void(0);" class="dropdown-item">-->
<!--            <i class="fe-settings mr-1"></i>-->
<!--            <span>Settings</span>-->
<!--          </a>-->

<!--          <div class="dropdown-divider"></div>-->

<!--          &lt;!&ndash; item&ndash;&gt;-->
<!--          <a href="javascript:void(0);" class="dropdown-item">-->
<!--            <i class="fe-headphones mr-1"></i>-->
<!--            <span>Help & Support</span>-->
<!--          </a>-->

<!--        </div>-->
<!--      </li>-->
<!--    </ul>-->
  </div>
</div>
<!-- end Topbar -->
