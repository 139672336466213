<footer class="footer">
<!--  <div class="container-fluid">-->
    <div class="row">
      <div class="col-md-6">
        {{year}} © Cámara Chilena de la Construcción
<!--        <a href="http://www.cdt.cl" target="_blank">CDT</a>-->
          | Desarrollado por <a href="https://www.sofdev.cl" target="_blank"><i class="mdi mdi-code-tags"></i> Sofdev SPA</a>
      </div>
      <div class="col-md-6">
        <div class="text-md-right footer-links d-none d-sm-block">
          <b>Versión: {{nVersion}}</b>
        </div>
      </div>
    </div>
<!--  </div>-->
</footer>
