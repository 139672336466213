import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';

import { UIModule } from '../shared/ui/ui.module';
import { LayoutComponent } from './layout.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import {NavbarModule} from './navbar/navbar.module';
import {PipesModule} from '../core/pipes/pipes.module';


@NgModule({
  declarations: [
      LayoutComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    NavbarComponent,
    BreadcrumbsComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        NgbDropdownModule,
        NgbCollapseModule,
        ClickOutsideModule,
        UIModule,
        NavbarModule,
        PipesModule,
    ]
})
export class LayoutsModule { }
