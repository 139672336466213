import {Route, RouterModule, Routes} from '@angular/router';
import {LoginGuard} from './core/guards/login.guard';
import {LayoutComponent} from './layouts/layout.component';
import {NoPageFoundComponent} from './shared/no-page-found/no-page-found.component';
import {LoginComponent} from './login/login/login.component';
import {ResetPasswordComponent} from './login/reset-password/reset-password.component';

export const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: ResetPasswordComponent},
    { path: '',
        component: LayoutComponent,
        canActivate: [ LoginGuard ],
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
    { path: '**', component: NoPageFoundComponent}
];
export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true, relativeLinkResolution: 'legacy' });
