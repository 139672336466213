import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagenPipe } from './imagen.pipe';
import { DecimalPipe } from './decimal.pipe';
import { PuntoComaPipe } from './punto-coma.pipe';

@NgModule({
    declarations: [ImagenPipe, DecimalPipe, PuntoComaPipe],
    exports: [
        ImagenPipe,
        DecimalPipe
    ],
    imports: [
        CommonModule
    ]
})
export class PipesModule { }
