/**
 * **Url de ruta de acceso al servidor de producción**
 * puerto de acceso **3005** , host: **www.app.cetcloud.cl**
 * direccion completa: **www.app.cetcloud.cl:3005**
 */
export const URL_SERVICIOS = 'https://ingresodehoras.cchc.cl:3001';

/**
 * **Url de ruta de acceso al servidor de pruebas**
 * Puerto de acceso **3025**, host: **www.app.cetcloud.cl**
 *
 */
// export const URL_SERVICIOS = 'http://52.67.140.20';

/**
 * Url de ruta de acceso a el servidor local con el puerto configurado
 * **Puerto:3001**, host: **http://localhost**
 */
// export const URL_SERVICIOS = 'http://localhost:3001';



