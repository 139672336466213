<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <!-- Logo -->
            <div class="text-center w-75 m-auto">
              <a routerLink="/account/login">
                <img src="assets/images/logo-cdt.png" alt="" height="120" />
              </a>
              <p class="text-muted mb-4 mt-3">Ingresar email para obtener las instrucciones de recuperación de contraseña.</p>
            </div>


            <form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>

              <app-ui-preloader [display]="loading"></app-ui-preloader>

              <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
              <ngb-alert type="success" *ngIf="success" [dismissible]="false">{{ success }}</ngb-alert>

              <div class="form-group mb-3" *ngIf="!success">
                <label for="email">Email</label>

                <input type="email" formControlName="email" class="form-control"
                       [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="Email" />

                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">El campo email es requerido</div>
                  <div *ngIf="f.email.errors.email">El formato ingresado no es valido</div>
                </div>
              </div>

              <div class="form-group mb-0 text-center" *ngIf="!success">
                <button class="btn btn-primary btn-block" type="submit">Enviar</button>
              </div>
            </form>

          </div> <!-- end card-body-->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">Volver al <a [routerLink]="['/login']" class="text-muted ml-1"><b>Login</b></a></p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
  2021 © Corporación de Desarrollo Tecnológico <a target="_blank" href="http://www.cdt.cl">CDT</a>
</footer>
