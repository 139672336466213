import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
// import {error} from 'util';

@Injectable(
    { providedIn: 'root' }
)
export class SubirArchivoService {

    constructor() { }


    subirArchivo( archivo: File, tipo: string, id: number ) {
        console.log('file: ' + archivo + ' ,tipo: ' + tipo + ', id: ' + id);
        return new Promise( (resolve, reject ) => {

            const formData = new FormData();
            const xhr = new XMLHttpRequest();

            formData.append( 'imagen', archivo, archivo.name );

            xhr.onreadystatechange = () => {

                if ( xhr.readyState === 4 ) {/*--- estado terminado ---*/

                    if ( xhr.status === 200 ) {
                        console.log( 'Imagen subida' );
                        resolve( JSON.parse( xhr.response ) );
                    } else {
                        console.log( 'Fallo la subida' );
                        reject( xhr.response );
                    }

                }
            };

            const url = URL_SERVICIOS + '/upload/' + tipo + '/' + id;
            xhr.open('PUT', url, true );
            xhr.send( formData );
        });
    }

}
