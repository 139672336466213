import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LOCALE_ID, NgModule} from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendProvider } from './core/helpers/fake-backend';

/**
 * Agregar el formato español en la aplicación completa, **Cambio en el formato de numeros**
 * y **cambio en el formato de fecha**
 */
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LoginModule} from './login/login.module';
import {APP_ROUTES} from './app.routes';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {BaseChartDirective} from 'ng2-charts';
import {DecimalPipe, registerLocaleData} from '@angular/common';
import {GaugeModule} from 'angular-gauge';
import {GaugeChartModule} from 'angular-gauge-chart';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    // AppRoutingModule,
    LoginModule,
    ReactiveFormsModule,
    GaugeModule.forRoot(),
    GaugeChartModule,
  ],
  exports: [
      ReactiveFormsModule
  ],
  providers: [
      BaseChartDirective,
      DecimalPipe,
    { provide: LOCALE_ID, useValue: 'es'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
