/*
* time_controlling_frontend - /core/services/autorizacion.service.ts
* @Autor: hburgos@sofdev.cl - www.sofdev.cl
* @Version: 1.0.0
* fecha: 07-2021
*/
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {URL_SERVICIOS} from '../../config/config';
import {UsuarioService} from '../usuario/usuario.service';
import swal from 'sweetalert';
import {Autorizacion} from '../../models/autorizacion.model';
import {Observable} from 'rxjs';
import {HelpersService} from '../helpers/helpers.service';

@Injectable(
    {providedIn: 'root'}
)
export class AutorizacionService {
    totalAutorizaciones: number;
    autorizaciones: Autorizacion [] = [];
    fecha = new Date();
    validaAutoriza = false;
    constructor(
        public http: HttpClient,
        public usuarioService: UsuarioService,
    ) {
        // this.listar();
        this.listarPorUsuarioMes(this.usuarioService.usuario.id, (this.fecha.getUTCMonth() ));
    }

    listarAutorizaciones(desde: number) {
        let url = URL_SERVICIOS + '/autorizacion/desde/' + desde;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalAutorizaciones = resp.total;
                return resp.autorizaciones;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listar() {
        let url = URL_SERVICIOS + '/v_autorizacion';
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalAutorizaciones = resp.total;
                this.autorizaciones = resp.v_autorizaciones;
                return resp.v_autorizaciones;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorUsuarioMes(usuario: number, mes: number) {
        let url = URL_SERVICIOS + '/v_autorizacion/usuario/' + usuario + '/mes/' + mes;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalAutorizaciones = resp.total;
                if (resp.total > 0) {
                    this.validaAutoriza = true;
                } else {
                    this.validaAutoriza = false;
                }
                console.log('valida: ' + this.validaAutoriza + ', mes: ' + mes + ', usuario: ' + usuario);
                this.autorizaciones = resp.v_autorizaciones;
                return resp.v_autorizaciones;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarVistaPorNombre(nombre: string) {
        let url = URL_SERVICIOS + '/v_autorizacion/nombre/' + nombre;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalAutorizaciones = resp.total;
                return resp.v_autorizaciones;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    cargarAutorizacion(id: number) {
        let url = URL_SERVICIOS + '/autorizacion/' + id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => resp.autorizaciones)
            .catch(err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    guardarAutorizacion(autorizacion: Autorizacion) {

        let url = URL_SERVICIOS + '/autorizacion';
        if (autorizacion.id) {
            /*- actualizando Autorizaciones **/
            url += '/' + autorizacion.id;
            url += '?token=' + this.usuarioService.token;

            return this.http.put(url, autorizacion)
                .map((resp: any) => {
                    swal('Autorización actualizada', '', 'success');
                    return resp.autorizaciones;
                })
                .catch(err => {
                    swal('Error al actualizar!', 'No fue posible actualizar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        } else {
            /*- creando Autorizaciones **/
            url += '?token=' + this.usuarioService.token;
            return this.http.post(url, autorizacion)
                .map((resp: any) => {
                    swal('Autorización creada correctamente', '', 'success');
                    return resp.autorizaciones;
                })
                .catch(err => {
                    swal('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        }
    }

    borrarAutorizacion(id: number) {
        let url = URL_SERVICIOS + '/autorizacion/' + id;
        url += '?token=' + this.usuarioService.token;

        return this.http.delete(url)
            .map(resp => {
                swal('autorización Borrada', 'autorización borrada correctamente', 'success');
                return resp;
            })
            .catch(err => {
                swal('Error al borrar!', 'No fue posible borrar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }
}
