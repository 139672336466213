import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import {RouterModule} from '@angular/router';
import {UIModule} from '../shared/ui/ui.module';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
      LoginComponent,
    ResetPasswordComponent,
    LockScreenComponent],
    imports: [
        CommonModule,
        RouterModule,
        UIModule,
        NgbAlertModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class LoginModule { }
