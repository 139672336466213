import { Injectable } from '@angular/core';
import {Pagina} from '../../models/paginas.model';
import {UsuarioService} from '../usuario/usuario.service';
import {Usuario} from '../../models/usuario.model';
import {Timecontrolling} from '../../models/timecontrolling.model';
import {TimecontrollingService} from '../mantenedores/timecontrolling.service';
import {Observable} from 'rxjs';
import {Feriado} from '../../models/feriado.model';
import {FeriadoService} from '../mantenedores/feriado.service';
import {Meses} from '../../models/meses.model';
import {AutorizacionService} from '../mantenedores/autorizacion.service';
import * as XLSX from 'xlsx';
interface Matriz {
  contador: number;
  series: number;
}

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  fechaBuscar = new Date();
  fecha = new Date();
  paginas: Pagina [] = [];
  usuario: Usuario;
  timesDiarios: Timecontrolling [] = [];
  feriados: Feriado [] = [];
  horas = 0;
  meses: Meses [] = [];
  public  contDiasFeriados = 0;
  constructor(
      public usuarioService: UsuarioService,
      public timecontrollingService: TimecontrollingService,
      public feriadoService: FeriadoService,
      public autorizacionService: AutorizacionService
  ) {
    this.usuario = this.usuarioService.usuario;
    // this.fecha.setUTCHours(this.fechaBuscar.getDate() + 3);
  }
  /**
   * **Reemplazar los puntos por una coma**
   *
   */
  reemplazarPuntoComa(valor: any) {
    let dato: number;
    if (valor === null) {
      dato = 0;
    } else {
      dato = valor.toString().replace('.' , ',');
    }
    return dato;
  }

  /*--- contar numero de paginas  ---*/
  contarPaginas(total: number, offset: number) {
    // this.paginas = [];
    let pag = total / offset;
    pag = Math.ceil(pag);
    if (total === 0) {
      pag = 0;
    }
    this.paginas = [
      {
        num: 1,
        offset: 1
      }
    ];
    for (let i = 2; i <= pag; i++) {
      this.paginas.push(
          {
            num: i,
            offset: (i - 1) * offset
          },
      );
    }
    return this.paginas;
  }

  /*--- promediar datos de un array ---*/
  public promediarArray(datos: any[]) {
    console.log('datos');
    console.log( datos);
    return datos.reduce((a, b) => a + b);
    // return 15;
  }

  /*--- verificacion de booleanos en selector de asistencias ---*/
  public verificaBooleano(valor: any) {
    if (valor === true) {
      return 1;
    } else {
      return 0;
    }
  }

  /*--------- Inicio Metodos relacionados a las fechas, semanas y meses -------------------------------------------------*/
  public fechaActual() {
    const mes = Number(this.fecha.getMonth()) + 1;
    return this.fecha.getFullYear() + '-' + mes + '-' + this.fecha.getDate();
  }

  public getFecha() {
    // this.fecha.gm
    return this.fecha;
  }

  public mesActual() {
    // const mes = Number(this.fecha.getMonth()) + 1;
    return Number(this.fecha.getUTCMonth() + 1);
  }

  public yearActual() {
    return Number(this.fecha.getUTCFullYear());
  }

  public semanaActual() {
    return  Number(this.fecha.getMonth());
  }
  /*--- retornar el dia de la semana en formato numero,
  * el día domingo retorna un 0, el lunes un 1 y así sucesivamente.
  ---*/
  public diaDeLaSemana( fecha: any) {
    const dia = new Date(fecha);
    // console.log(dia.getUTCDay() + ' - ' + dia.getDate());
    return dia.getUTCDay();
  }

  /*--- validar que la fecha seleccionada no corresponda a un dia de fin de semana,
  * en caso de corresponder a un fin de semana, retornar false
  ---*/
  public validarFinSemana(fecha: any) {
     let valida = false;
     // console.log('fecha: ', fecha);
     const dia = this.diaDeLaSemana(fecha);
     switch (dia) {
       case 0: /*--- domingo ---*/
         valida = false;
         break;
       case 6: /*--- sabado ---*/
         valida = false;
         break;
       default:
         valida = true;
         break;
     }
     // console.log('Día: ' + dia + ' , fecha: ' + fecha + ', Valida: ' + valida);
     return valida;
  }

  /*--- validar que la fecha ingresada no corresponda a un feriado,
   *  en caso de corresponder a un feriado retorna false
   ---*/
  private validarFeriado(fecha: any) {
    localStorage.setItem('contFeriados', '0');
    this.feriadoService.listarPorFecha(fecha)
        .subscribe( feriados => {
          // cont = feriados.length;
          if (feriados) {
            console.log('feriado');
            // localStorage.setItem('contFeriados', String(Number(localStorage.getItem('contFeriados') + 1)));
            this.contDiasFeriados++;
          }
        });
  }

  /*--------- Fin Metodos relacionados a las fechas, semanas y meses ----------------------------------------------------*/

  /*--- calcular horas diarias del usuario, estas dependeran de cada usuario ---*/
  public calculoHorasDiaria(fecha: any) {
      this.horas = 0;
      this.timecontrollingService.listarPorFecha(fecha)
          .subscribe( datos => {
            // console.log(datos);
            for (const times of datos) {
              this.horas += times.horas;
              // console.log('hh: ' + this.horas);
              return this.horas;
            }
          });
      // console.log('Horas acumuladas: ' + this.horas);

  }

  /*--- validar que las horas ingresadas son igual o
  * menores a las horas pactadas por el usuario, en caso de complir retornar
  */
  public validarHoras(horas: number) {
    // console.log('horas totales: ' + horas + ', horas usuario: ' + this.usuarioService.usuario.horas);
    if (horas <= this.usuarioService.usuario.horas) {
      return  true;
    } else {
      return  false;
    }
  }

  /*--------- Inicio calculos numericos -------------------------------------------------*/
  calcularPorcentaje(parcial: number, total: number) {
    if (total === 0) {
      return 0
    } else {
      return parcial * 100 / total;
    }
  }

  /**
   * **Calcular el valor del numero en base a un porcentaje entregado**,
   * retorna el valor numerico del porcentaje entregado.
   * el numero es equivalente al valor del 100% y el porcentaje es el valor alcual se le desea sacar un porcentaje
   * @params numero, porcentaje
   */
  calcularNumeroPorcentaje(porcentaje: number, numeroPorcentaje: number) {
    return porcentaje * numeroPorcentaje / 100;
  }
  /*--------- Fin calculos numericos ----------------------------------------------------*/

  /*--- calcular las horas a trabajar en el mes de cada usuario, descontando los feriados, fin de semanas  ---*/
  public calcularDiasHabilesMesUsuario(usuario: Usuario, mesFiltro: number, yearFiltro: number) {
    // console.log('calcular horas mes usuario:  ');
    this.feriadoService.listarPorMesYear(mesFiltro, yearFiltro).subscribe();
    const fecha = new Date();
    fecha.setMonth(mesFiltro - 1);
    const mesInicial = fecha.getUTCMonth();
    fecha.setFullYear(yearFiltro);
    // fecha.setDate(1);
    fecha.setDate(1); /*--- reiniciar fecha a inicio de mes ---*/
    let cont = 0;
    let totalFinSemana = 0;
    const totalFeriados = Number(localStorage.getItem('feriados'));
    // this.feriadoService.listarPorMesYear(mesFiltro, yearFiltro).subscribe();
    // totalFeriados = Number(localStorage.getItem('feriados'));
    for (let i = 0; i < 33; i++) {
      /*--- buscar datos que correspondan solamente al mes seleccionado ---*/
      if (mesInicial === fecha.getUTCMonth()) {
        if (this.validarFinSemana(fecha.getFullYear() + '-' + (fecha.getUTCMonth() + 1) + '-' + fecha.getUTCDate()) === false) {
          totalFinSemana++;
        }
        cont++;
      }
      fecha.setDate(fecha.getDate() + 1); /*--- sumar un dia a la fecha ---*/
    }
    // console.warn('Total feriados: ' + totalFeriados);
    console.log('Dias de mes: ' + cont + ', Dias habiles: ' + (cont - totalFinSemana) + ', fin semana: ' + totalFinSemana);
    // console.log('Total horas mes usuario: ' + (cont - totalFinSemana) * usuario.horas);
    return (cont - (totalFinSemana ) );
    // return (cont - totalFinSemana) * usuario.horas;
  }

  /**
   * validar que se encuentre en los primeros cinco días habiles del mes para poder
   * modificar el mes anterior
   */
  public validarPrimeraSemana(fechaBuscar: any) {
    // console.warn('valida autoriza: ' + this.autorizacionService.validaAutoriza);
    // console.clear();
    const fecha = new Date(this.fechaActual());
    const fecha2 = new Date(fechaBuscar);
    const fechaValida = new Date(fecha.getFullYear() + '-' + (fecha.getUTCMonth() + 1) + '-1');
    let sumaDiasHabiles = 0;
    /*--- validaYear valida que este en los primeros días habiles del año siguiente ---*/
    if (this.fecha.getUTCMonth() === 0
        && (this.fecha.getFullYear() - fecha2.getFullYear() === 1)
        &&  fecha2.getUTCMonth() === 11 && fecha.getUTCDate() <= 8) {
      return true;
    }
    // this.autorizacionService.listarPorUsuarioMes(this.usuarioService.usuario.id, fecha.getUTCMonth() + 1)
    //     .subscribe()
    // console.log('dias habiles: ' + sumaDiasHabiles + ', fecha: ' + fechaBuscar);
    // console.log('fecha actual: ' + fecha.getDate() + '-' + fecha.getUTCMonth() + '-' + fecha.getFullYear());
    // console.log('fecha buscar: ' + fecha2.getDate() + '-' + fecha2.getUTCMonth() + '-' + fecha2.getFullYear());
    /*--- buscar registros que correspondan al mismo mes ---*/
    if (fecha.getUTCMonth() === fecha2.getUTCMonth() && fecha.getFullYear() === fecha2.getFullYear()) {
      // console.log('registros corresponden al mismo mes');
      return true;
    } else {
      /*--- buscar los registros del mes anterior solo si esta en los primeros 5 días habiles del mes actual ---*/
      if (fecha2.getUTCMonth() === (fecha.getUTCMonth() - 1) && fecha.getFullYear() === fecha2.getFullYear() ) {
        for (let i = 0; i <= fecha.getDate(); i++ ) {
          /*---  ---*/
          fechaValida.setDate(i + 1);
          // this.validarFeriado(fechaValida.getFullYear() + '-' + (fechaValida.getUTCMonth() + 1) + '-' + fechaValida.getDate());
          // console.warn('feriados: ' + this.contDiasFeriados);
          // sumar los días diferentes a los sabados y domingos
          if (fechaValida.getDay() > 0 && fechaValida.getDay() < 6) {
            sumaDiasHabiles++;
          }
          // console.log('dias habiles: ' + sumaDiasHabiles + ', fecha: ' + fechaBuscar);
          if (sumaDiasHabiles > 5 ) {
            /*--- buscar si existe una autorización del usuario ---*/
            if (this.autorizacionService.validaAutoriza) {
              return true;
            } else {
              return false;
            }
          }
        }
        if (sumaDiasHabiles <= 5 ) {
          return true;
        }
      } else {/*--- si el mes seleccionado es diferente al mes anterior o el actual ---*/
        // console.error('registro corresponde a una fecha diferente al mes anterior');
        return false;
      }
    }
  }



  public getDecimal(numero: number) {
    numero = Number(numero);
    return numero.toFixed(1);
  }

  /*--- generar un color random ---*/
  public colorRandom() {
    const hexadecimal = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F');
    let randomColor = '#';
    for (let i = 0; i < 6; i++) {
      const random = Math.round(Math.random() * 9);
      // const posarray = aleatorio(0, hexadecimal.length);
      // randomColor += hexadecimal[posarray];
      randomColor += random;
    }
    return randomColor;
  }

  generarPasswordRandom() {
    const hexadecimal = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F');
    let randomColor = '#';
    for (let i = 0; i < 6; i++) {
      const random = Math.round(Math.random() * 9);
      // const posarray = aleatorio(0, hexadecimal.length);
      // randomColor += hexadecimal[posarray];
      randomColor += random;
    }
    return randomColor;
  }

  async contarFeriados(mes: number, year: number) {
    this.feriadoService.listarPorMesYear(mes, year).subscribe();
  }

  /**
   * **retornar el nombre del mes en base a un array de datos y al mes en curso**
   */
  getNombreMes() {
      const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
      const fecha = new Date();
      return meses[fecha.getUTCMonth() - 1];
  }

  getMes(mes) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const fecha = new Date();
    return meses[mes - 1];
  }

  getMeses() {
    this.meses = [];
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    for (let i = 0; i < 12; i++) {
      this.meses.push({id: i + 1, nombre: meses[i]});
    }
    return this.meses;
  }

  /**
   * **Generar reporte en excel** con los datos entregados
   *
   * @param nombreArchivo: string, datos: any
   */
  generarExcel(nombreArchivo: string, datos: any, id: string) {
    console.log('exportar-excel');
    // this.verReporte = true;
    const filename = nombreArchivo + '.xlsx';
    /**
     * Hoja en libro de trabajo de excel
     */
    const workSheet = document.getElementById(id);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datos);
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(workSheet);
    /**
     * generar libro de trabajo **Work book**
     */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Datos');
    /**
     * Guardar archivo
     */
    XLSX.writeFile(wb, filename);
    // this.verReporte = false;
  }
}
