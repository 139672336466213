import { Component, OnInit } from '@angular/core';
// import {packageJson} from '../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  fechaActual = new Date();
  year = this.fechaActual.getFullYear();
  nVersion = '1.1.1';
  constructor() { }

  ngOnInit() {
    // this.nVersion = packageJson.version;
  }

}
