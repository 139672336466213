<div class="col-12">
  <div class="page-title-box">
    <div class="page-title-right">
      <ol class="breadcrumb m-0">
        <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"><i class=" mdi mdi-home"></i> Inicio</a></li>
        <li class="breadcrumb-item"><a >{{ pages }}</a></li>
        <li class="breadcrumb-item active"><i class="{{ icon }}"></i> {{ label }}</li>
      </ol>
    </div>
    <h4 class="page-title"><i class="{{ icon }}"></i> {{ label }}</h4>
  </div>
</div>
