import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UsuarioService} from '../../core/services/usuario/usuario.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: []
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {

  resetForm: FormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      public usuarioService: UsuarioService
  ) { }

  ngOnInit() {

    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.usuarioService.mostrarPorEmail(this.resetForm.value.email)
        .subscribe( datos => {
          console.log(datos.ok);
          if (datos.ok) {
            this.usuarioService.recuperarPassword(this.resetForm.value.email)
                .subscribe( data => {
                  if (data) {
                    this.submitted = true;

                    // stop here if form is invalid
                    if (this.resetForm.invalid) {
                      return;
                    }

                    this.loading = true;

                    console.log(this.resetForm.value.email);
                    setTimeout(() => {
                      this.loading = false;
                      this.success = 'Se ha enviado un email con los datos para la recuperación de su \n' +
                          '          contraseña, en caso de no recibir el email contáctese con el administrador del sistema';
                    }, 1000);
                  }
                });
          } else {
            this.success = datos.mensaje;
          }
        });
  }

}
