/*
* time_controlling_frontend - /core/services/feriado/feriado.service.ts
* @autor: hburgos089@gmail.com
* @version: 1.0.0
* fecha: 08-2020
*/
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {URL_SERVICIOS} from '../../config/config';
import {UsuarioService} from '../usuario/usuario.service';
import swal from 'sweetalert';
import {Feriado} from '../../models/feriado.model';
import {Observable} from 'rxjs';

@Injectable(
    { providedIn: 'root' }
)
export class FeriadoService {
    totalFeriados = 0;
    nFeriados = 0;
    i = 0;
    j = 0;
    public total = 0;

    constructor(
        public http: HttpClient,
        public usuarioService: UsuarioService
    ) {
    }

    listarFeriados(desde: number) {
        let url = URL_SERVICIOS + '/feriado/desde/' + desde;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalFeriados = resp.total;
                return resp.feriados;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listar() {
        let url = URL_SERVICIOS + '/feriado';
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalFeriados = resp.total;
                return resp.feriados;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorFecha(fecha: any) {
        let url = URL_SERVICIOS + '/feriado/fecha/' + fecha;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalFeriados = resp.total;
                this.nFeriados = resp.total;
                return resp.total;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorMesYear(mes: number, year: number) {
        let url = URL_SERVICIOS + '/v_feriado/mes/' + mes + '/year/' + year;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalFeriados = resp.total;
                this.total = resp.total;
                localStorage.setItem('feriados', resp.total);
                return resp.total;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorFechaCalendario(fecha: any, i: number, j: number) {
        let url = URL_SERVICIOS + '/feriado/fecha/' + fecha;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalFeriados = resp.total;
                this.i = i;
                this.j = j;
                return resp.feriados;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

      contarFeriados(mes: number, year: number) {
        this.listarPorMesYear(mes, year).subscribe( datos => {
            this.total = datos;
        });
        setTimeout( () => {
           return this.total;
              }, 2000);
    }

    listarVistaPorNombre(nombre: string) {
        let url = URL_SERVICIOS + '/v_feriado/nombre/' + nombre;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalFeriados = resp.total;
                return resp.v_feriados;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    cargarFeriado(id: number) {
        let url = URL_SERVICIOS + '/feriado/' + id;
        url += '?token=' + this.usuarioService.token;
        return this.http.get(url)
            .map((resp: any) => resp.feriados)
            .catch(err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    guardarFeriado(feriado: Feriado) {

        let url = URL_SERVICIOS + '/feriado';
        if (feriado.id) {
            /*- actualizando Feriados **/
            url += '/' + feriado.id;
            url += '?token=' + this.usuarioService.token;

            return this.http.put(url, feriado)
                .map((resp: any) => {
                    swal('Feriado actualizado', feriado.descripcion, 'success');
                    return resp.feriados;
                })
                .catch(err => {
                    swal('Error al actualizar!', 'No fue posible actualizar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        } else {
            /*- creando Feriados **/
            url += '?token=' + this.usuarioService.token;
            return this.http.post(url, feriado)
                .map((resp: any) => {
                    swal('Feriado Creado', feriado.descripcion, 'success');
                    return resp.feriados;
                })
                .catch(err => {
                    swal('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        }
    }

    borrarFeriado(id: number) {
        let url = URL_SERVICIOS + '/feriado/' + id;
        url += '?token=' + this.usuarioService.token;

        return this.http.delete(url)
            .map(resp => {
                swal('feriado Borrado', 'feriado borrado correctamente', 'success');
                return resp;
            })
            .catch(err => {
                swal('Error al borrar!', 'No fue posible borrar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }
}
