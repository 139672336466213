export class Usuario {
    constructor(
        public nombre: string,
        public email: string,
        public telefono: number,
        public password: string,
        public role: string,
        public estado: number,
        public rut: string,
        public area: number,
        public horas: number,
        public visualizacion: number,
        public descripcion?: string,
        public rPassword?: string,
        public nombreEstado?: string,
        public nombreArea?: string,
        public passwordNueva?: string,
        public pass?: string,
        public img?: string,
        public porCompletado?: number,
        public horasMes?: number,
        public id?: number
    ) { }
}
