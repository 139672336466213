<section >
    <div class="account-pages mt-5 mb-5" >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="card">

                        <div class="card-body p-4">
                            <!-- Logo -->
                            <div class="text-center w-75 m-auto">
                                <a routerLink="/">
                                    <img src="assets/images/logo-CChc.png" alt="" height="150">
                                </a>
                                <p class="text-muted mb-4 mt-3">Ingresar email y password para acceder al sistema.</p>
                            </div>

                            <form class="needs-validation"  [formGroup]="forma"   (ngSubmit)="login()">

                                <!--              <app-ui-preloader [display]="loading"></app-ui-preloader>-->

                                <!--              <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>-->

                                <!--              <div class="form-group mb-3">-->
                                <!--                <label for="emailaddress"><i class="ti-email"></i> Email </label>-->
                                <!--                <input class="form-control" type="email" [ngModel]="email" name="email"  placeholder="Ingresar email"  required>-->
                                <!--              </div>-->
                                <div class="form-group col-md-12 col-sm-12">
                                    <label for="email" class="col-form-label"><i class="ti-email"></i> Email </label>
                                    <input type="text" class="form-control" name="email" formControlName="email" placeholder="Ingresar email"
                                           [ngClass]="{'is-invalid' : !forma.get('email').valid, 'is-valid' : forma.get('email').valid}" >
                                    <div *ngIf="forma.controls['email'].errors?.required">
                                        <span class="form-text text-muted"><small>El campo email es necesario.</small></span>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12">
                                    <label for="password" class="col-form-label"><i class="ti-lock"></i> Password </label>
                                    <input type="text" class="form-control" name="password" formControlName="password"
                                           placeholder="Ingresar password" *ngIf="viewPass"
                                           [ngClass]="{'is-invalid' : !forma.get('password').valid, 'is-valid' : forma.get('password').valid}" >
                                    <input type="password" class="form-control" name="password"  *ngIf="!viewPass"
                                           formControlName="password" placeholder="Ingresar password"
                                           [ngClass]="{'is-invalid' : !forma.get('password').valid, 'is-valid' : forma.get('password').valid}" >
                                    <div *ngIf="forma.controls['password'].errors?.required">
                                        <span class="form-text text-muted"><small>El campo password es necesario.</small></span>
                                    </div>
                                    <div *ngIf="forma.controls['password'].errors?.minlength">
                                        <span class="form-text text-muted"><small>Inferior al mínimo de campos requeridos.</small></span>
                                    </div>
                                </div>
                                <!--              <div class="form-group mb-3">-->
                                <!--                <label for="password"><i class="ti-lock"></i> Password</label>-->
                                <!--                <input class="form-control" type="password" required="" ngModel name="password" placeholder="Ingresar pasword">-->
                                <!--              </div>-->

                                <div class="form-group mb-0 text-center">

                                </div>

                                <button class="btn btn-primary btn-block" type="submit" [disabled]="forma.invalid" *ngIf="!btnStatus"> Ingresar </button>
                                <button class="btn btn-primary btn-block" type="button" disabled *ngIf="btnStatus">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span
                                        class="sr-only">Loading...</span>
                                </button>
                            </form>
                            <br>
                            <div class="form-group mb-3">

                                <div _ngcontent-bhc-c19="" class="custom-control custom-switch">
                                    <input _ngcontent-bhc-c19="" class="custom-control-input" id="customSwitch1" type="checkbox" [(ngModel)]="viewPass"  (change)="verPassword($event.target.value)">
                                    <label _ngcontent-bhc-c19="" class="custom-control-label" for="customSwitch1">Mostrar Password </label>
                                </div>
                            </div>
                            <br>
                            <!-- codigo google sign in -->
<!--                            <div id="g_id_onload"-->
<!--                                 data-client_id="146352848250-g0tn0q0j7da5nkr04mtru3dm6em6tb02.apps.googleusercontent.com"-->
<!--                                 data-context="use"-->
<!--                                 data-ux_mode="popup"-->
<!--                                 data-login_uri="https://ingresodehoras.cchc.cl"-->
<!--                                 data-itp_support="true"-->
<!--                            >-->
<!--                            </div>-->

<!--                            <div class="g_id_signin"-->
<!--                                 data-type="standard"-->
<!--                                 data-shape="rectangular"-->
<!--                                 data-theme="outline"-->
<!--                                 data-text="signin_with"-->
<!--                                 data-size="large"-->
<!--                                 data-logo_alignment="left">-->
<!--                            </div>-->
                            <div id="buttonDiv"></div>


                            <!--            <p class="mt-3 text-muted"><strong>User:</strong> pruebas@pruebas.cl, <strong>Password:</strong> Pruebas-->
                            <!--            </p>-->
                        </div> <!-- end card-body -->
                    </div>
                    <!-- end card -->

                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <!--            <p>-->
<!--                            <a [routerLink]="['/reset-password']" class="text-muted ml-1">¿Desea recuperar la contraseña?</a>-->
                            <!--            </p>-->

                        </div> <!-- end col -->
                    </div>
                    <!-- end row -->

                </div> <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
    <!-- end page -->

    <footer class="footer footer-alt">
        {{fecha.getFullYear()}} © Cámara Chilena de la Construcción
<!--        <a target="_blank" href="http://wwww.cdt.cl">CDT</a>-->
<!--        <i class="mdi mdi-code-not-equal-variant"></i>-->
    </footer>
</section>


<!--style="background-image: url('./assets/images/camara-chilena-construccion01-1.jpg');-->
<!--background-repeat: initial;-->
<!--background-size: cover;-->
<!--background-position: center"-->


