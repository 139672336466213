import { Injectable } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import swal from 'sweetalert';


import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Router } from '@angular/router';
import { SubirArchivoService } from '../subir-archivo/subirArchivo.service';
import {Pagina} from '../../models/paginas.model';
import {FormGroup} from '@angular/forms';
import {Pass} from '../../models/pass.model';
import Swal from "sweetalert2";
export interface UsuarioToken {
    id: number;
    nombre: string;
    role: string;
    estado: number;
}

// @ts-ignore
@Injectable(
    { providedIn: 'root' }
)
export class UsuarioService {
    totalUsuarios: number;
    // @ts-ignore
    usuario: Usuario = new Usuario();
    user2: Usuario;
    user: Usuario;
    token: string;
    menu: any[] = [];
    paginas: Pagina [] = [];
    constructor(
        public http: HttpClient,
        public router: Router,
        public subirArchivoService: SubirArchivoService,
    ) {
        this.cargarStorage();
    }

    renuevaToken(usuario: UsuarioToken) {

        let url = URL_SERVICIOS + '/login/renueva_token/usuario/' + usuario;
        url += '?token=' + this.token;

        return this.http.get( url )
            .map( (resp: any) => {

                this.token = resp.token;
                localStorage.setItem('token', this.token );
                console.log('Token renovado');
                return true;
            })
            .catch( err => {
                this.router.navigate(['/login']);
                swal( 'No se pudo renovar token', 'No fue posible renovar token', 'error' );
                return Observable.throw( err );
            });


    }

    estaLogueado() {
        return ( this.token.length > 5 ) ? true : false;
    }

    cargarStorage() {

        if ( localStorage.getItem('token')) {
            this.token = localStorage.getItem('token');
            this.usuario = JSON.parse( localStorage.getItem('usuario') );
            this.user2 = JSON.parse( localStorage.getItem('usuario') );
            // this.menu = JSON.parse( localStorage.getItem('menu') );
        } else {
            this.token = '';
            this.usuario = null;
            this.menu = [];
        }

    }

    guardarStorage( id: number, token: string, usuario: Usuario, menu: any ) {
        const iD = String(id);
        localStorage.setItem('id', iD );
        localStorage.setItem('token', token );
        localStorage.setItem('usuario', JSON.stringify(usuario) );
        localStorage.setItem('user', JSON.stringify(usuario));
        localStorage.setItem('menu', JSON.stringify(menu) );
        sessionStorage.setItem('idUsuario', iD);

        this.usuario = usuario;
        this.token = token;
        this.menu = menu;
        // console.log(this.usuario);
    }

    logout() {
        // @ts-ignore
        swal({
            title: '¿Estas seguro?',
            text: 'Esta a punto de cerrar sesión',
            icon: 'warning',
            // buttons: true,
            dangerMode: true,
        })
            .then( cerrarSession => {

                if (cerrarSession) {
                    this.usuario = null;
                    this.user2 = null;
                    this.token = '';
                    this.menu = [];
                    this.borrarStorage();

                    this.router.navigate(['/login']);
                }
            });
    }

    logoutForzado() {
        this.usuario = null;
        this.user2 = null;
        this.token = '';
        this.menu = [];

        this.borrarStorage();

        this.router.navigate(['/login']);
    }

    // loginGoogle( token: string ) {
    //
    //     const url = URL_SERVICIOS + '/login/google';
    //
    //     return this.http.post( url, { token } )
    //         .map( (resp: any) => {
    //             this.guardarStorage( resp.id, resp.token, resp.usuario, resp.menu );
    //             return true;
    //         });
    //
    //
    // }
    /**
     * **Limpiar la local storage y session storage**
     * borrar todos los registros de la local storage
     */
    borrarStorage() {
        /*--- local storage ---*/
        localStorage.removeItem('token');
        localStorage.removeItem('usuario');
        localStorage.removeItem('menu');
        localStorage.removeItem('user');
        localStorage.removeItem('feriados');
        localStorage.removeItem('id');
        /*--- session storage ---*/
        sessionStorage.removeItem('idUsuario');
    }

    login(usuario: Usuario, recordar: boolean = false) {

        if ( recordar ) {
            localStorage.setItem('email', usuario.email );
        } else {
            localStorage.removeItem('email');
        }

        const url = URL_SERVICIOS + '/login';
        return this.http.post( url, usuario )
            .map( (resp: any) => {
                if (resp.ok) {
                    this.guardarStorage( resp.id, resp.token, resp.usuario, resp.menu );
                    this.user = resp.usuario;
                    // console.log(this.user);
                    return true;
                }
                else {
                    Swal.fire({
                        type: 'error',
                        title: '¡Acceso denegado!!',
                        text: 'Esta cuenta no posee permiso para acceder al sistema o escribió mal la contraseña, verifique sus datos eh intente nuevamente. ',
                        footer: 'En caso de algún problema contáctese con el administrador del sistema',
                        timer: 3500
                    });
                    this.borrarStorage();
                    return false;
                }
            })
            .catch( err => {

                swal( 'Login!', err.error.mensaje, 'error' );
                return Observable.throw( err );
                // return false;
            });

    }

    loginWithGoogle(usuario: Usuario, recordar: boolean = false) {

        if ( recordar ) {
            localStorage.setItem('email', usuario.email );
        } else {
            localStorage.removeItem('email');
        }

        const url = URL_SERVICIOS + '/login_with_google';
        return this.http.post( url, usuario )
            .map( (resp: any) => {
                if (resp.ok) {
                    this.guardarStorage( resp.id, resp.token, resp.usuario, resp.menu );
                    this.user = resp.usuario;
                    // console.log(this.user);
                    return true;
                }

            })
            .catch( err => {

                swal( 'Login!', err.error.mensaje, 'error' );
                return Observable.throw( err );
                // return false;
            });

    }

    crearUsuario( usuario: Usuario ) {

        const url = URL_SERVICIOS + '/usuario';

        return this.http.post( url, usuario )
            .map( (resp: any) => {

                swal('Usuario creado', usuario.email, 'success' );
                return resp.usuario;
            })
            .catch( err => {
                swal( err.error.mensaje, err.error.errors.message, 'error' );
                return Observable.throw( err );
            });
    }

    cambiarImagen( archivo: File, id: number ) {
        // const ID = String(id);
        this.subirArchivoService.subirArchivo( archivo, 'usuarios', id )
            .then( (resp: any) => {
                this.usuario.img = resp.usuarios.img;
                swal( 'Imagen Actualizada', this.usuario.nombre, 'success' );
                this.guardarStorage( id, this.token, this.usuario, this.menu );

            })
            .catch( resp => {
                console.log( resp );
            }) ;

    }

    cargarUsuario(id: number) {
        let url = URL_SERVICIOS + '/usuario/' + id;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => resp.usuarios)
            .catch(err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    guardarUsuario( usuario: Usuario) {

        let url = URL_SERVICIOS + '/usuario';
        if (usuario.id) {
            /*- actualizando Usuarios **/
            url += '/' + usuario.id;
            url += '?token=' + this.token;

            return this.http.put(url, usuario)
                .map((resp: any) => {
                    if ( usuario.id === this.usuario.id ) {
                        const usuarioDB: Usuario = resp.usuarios;
                        this.guardarStorage( usuarioDB.id, this.token, usuarioDB, this.menu );
                        console.log('Usuario actualizado');
                    }
                    swal('Usuario actualizado', usuario.nombre, 'success');
                    return resp.usuarios;
                })
                .catch(err => {
                    swal('Error al actualizar!', 'No fue posible actualizar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        } else {
            /*- creando Usuarios **/
            url += '?token=' + this.token;
            return this.http.post(url, usuario)
                .map((resp: any) => {
                    swal('Usuario Creado', usuario.nombre, 'success');
                    return resp.usuarios;
                })
                .catch(err => {
                    swal('Error al guardar!', 'No fue posible guardar el registro. ' + err.error.mensaje, 'error');
                    return Observable.throw(err);
                });
        }
    }

    cambiarPassword(usuario: Usuario, pass: Pass) {
        let url = URL_SERVICIOS + '/usuario/password/' + usuario.id;
        url += '?token=' + this.token;
        return this.http.put(url, pass)
            .map( (resp: any) => {
                // this.totalUsuarios = resp.total;
                return resp.mensaje;
            })
            .catch( err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    resetPassword(usuario: Usuario) {
        let url = URL_SERVICIOS + '/usuario/resetPassword/' + usuario.id;
        url += '?token=' + this.token;
        return this.http.put(url, usuario)
            .map( (resp: any) => {
                // this.totalUsuarios = resp.total;
                console.log(resp);
                return resp.mensaje;
            })
            .catch( err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarUsuarios( desde: number ) {
        console.log( 'desde: ' + desde);
        let url = URL_SERVICIOS + '/usuario/desde/' + desde;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.usuarios;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listar() {
        let url = URL_SERVICIOS + '/usuario/';
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.usuarios;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarPorEstado(estado: number) {
        let url = URL_SERVICIOS + '/v_usuario/estado/' + estado;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.v_usuarios;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarVistaPorArea( area: number) {
        let url = URL_SERVICIOS + '/v_usuario/area/' + area;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.v_usuarios;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarVistaPorRole(role: string) {
        let url = URL_SERVICIOS + '/v_usuario/role/' + role;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.v_usuarios;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarVistaPorNombre( nombre: string) {
        let url = URL_SERVICIOS + '/v_usuario/nombre/' + nombre;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map( (resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.v_usuarios;
            })
            .catch( err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    listarVistaPorNombreArea( nombre: string, area: number) {
        let url = URL_SERVICIOS + '/v_usuario/nombre/' + nombre + '/area/' + area;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map( (resp: any) => {
                this.totalUsuarios = resp.total;
                return resp.v_usuarios;
            })
            .catch( err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    borrarUsuario(id: number) {
        let url = URL_SERVICIOS + '/usuario/' + id;
        url += '?token=' + this.token;

        return this.http.delete(url)
            .map(resp => {
                swal('Usuario Borrado', 'usuario borrado correctamente', 'success');
                return resp;
            })
            .catch(err => {
                swal('Error al borrar!', 'No fue posible borrar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    passwordDefault(usuario: Usuario) {
        let url = URL_SERVICIOS + '/login/default';
        url += '?token=' + this.token;
        return this.http.post(url, usuario)
            .map( (resp: any) => {
                // console.log(resp);
                return resp.status;
            })
            .catch( err => {
                swal('Error al cargar!', 'No fue posible cargar el registro. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    /*--- recuperación de contraseña ---*/
    mostrarPorEmail(email: string) {
        let url = URL_SERVICIOS + '/usuario/email/' + email;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    recuperarPassword(email: string) {
        let url = URL_SERVICIOS + '/usuario/recuperar_password/' + email;
        url += '?token=' + this.token;
        return this.http.get(url)
            .map((resp: any) => {
                this.totalUsuarios = resp.total;
                return resp;
            })
            .catch(err => {
                swal('Error al listar!', 'No fue posible listar los datos. ' + err.error.mensaje, 'error');
                return Observable.throw(err);
            });
    }

    vistaReporteDirectorio() {
        this.usuario.role = 'USER_ROLE';
    }
}
